export const data = {
  stylesheets: [
    {
        "_id" : "64f5bd48134d5e63d92d39b1",
        "name" : "Hygiene passport",
        "description" : "Hygiene passport stylesheet",
        "backgroundColor" : "#038431",
        "fontColor" : "#FFFFFF",
        "createdBy" : "5b1fa75598a9cb165a5fa758",
        "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
        "createdAt" : "2023-09-04T11:19:36.461+0000",
        "updatedAt" : "2023-09-04T11:19:36.461+0000",
        "types" : [
            "environment"
        ]
    },
    {
        "_id" : "64f5ce56c062fcc020481d4e",
        "name" : "Alcohol passport",
        "description" : "Alcohol passport stylesheet",
        "backgroundColor" : "#367ce6",
        "fontColor" : "#FFFFFF",
        "createdBy" : "5b1fa75598a9cb165a5fa758",
        "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
        "createdAt" : "2023-09-04T11:19:36.461+0000",
        "updatedAt" : "2023-09-04T11:19:36.461+0000",
        "types" : [
            "environment"
        ]
    },
    {
        "_id" : "64f5ce89c062fcc020481d8d",
        "name" : "Work safety card",
        "description" : "Work safety card stylesheet",
        "backgroundColor" : "#22525d",
        "fontColor" : "#FFFFFF",
        "createdBy" : "5b1fa75598a9cb165a5fa758",
        "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
        "createdAt" : "2023-09-04T11:19:36.461+0000",
        "updatedAt" : "2023-09-04T11:19:36.461+0000",
        "types" : [
            "environment"
        ]
    },

    {
        "_id" : "64f52d42134d5e63d92d3921",
        "name" : "Yellow",
        "description" : "Default yellow stylesheet",
        "backgroundColor" : "#FFF6D3",
        "fontColor" : "#051F24",
        "createdBy" : "5b1fa75598a9cb165a5fa758",
        "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
        "createdAt" : "2023-09-04T11:19:36.461+0000",
        "updatedAt" : "2023-09-04T11:19:36.461+0000",
        "types" : [
            "practice", "course", "lesson"
        ]
    },
    {
        "_id" : "64f52d43134d5363d32d3921",
        "name" : "Orange",
        "description" : "Default orange stylesheet",
        "backgroundColor" : "#FDE3B3",
        "fontColor" : "#051F24",
        "createdBy" : "5b1fa75598a9cb165a5fa758",
        "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
        "createdAt" : "2023-09-04T11:19:36.461+0000",
        "updatedAt" : "2023-09-04T11:19:36.461+0000",
        "types" : [
            "practice", "course", "lesson"
        ]
    },    
    {
        "_id" : "64f52442134d5e63d9243924",
        "name" : "Red",
        "description" : "Default red stylesheet",
        "backgroundColor" : "#FDECEA",
        "fontColor" : "#051F24",
        "createdBy" : "5b1fa75598a9cb165a5fa758",
        "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
        "createdAt" : "2023-09-04T11:19:36.461+0000",
        "updatedAt" : "2023-09-04T11:19:36.461+0000",
        "types" : [
            "practice", "course", "lesson"
        ]
    },    
    {
        "_id" : "64f59445134d2e6357243954",
        "name" : "Purple",
        "description" : "Default purple stylesheet",
        "backgroundColor" : "#E6E4EA",
        "fontColor" : "#051F24",
        "createdBy" : "5b1fa75598a9cb165a5fa758",
        "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
        "createdAt" : "2023-09-04T11:19:36.461+0000",
        "updatedAt" : "2023-09-04T11:19:36.461+0000",
        "types" : [
            "practice", "course", "lesson"
        ]
    },    
    {
        "_id" : "64f59742134d506359e43954",
        "name" : "Blue",
        "description" : "Default blue stylesheet",
        "backgroundColor" : "#DCF5FA",
        "fontColor" : "#051F24",
        "createdBy" : "5b1fa75598a9cb165a5fa758",
        "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
        "createdAt" : "2023-09-04T11:19:36.461+0000",
        "updatedAt" : "2023-09-04T11:19:36.461+0000",
        "types" : [
            "practice", "course", "lesson"
        ]
    },   
    {
        "_id" : "64f5914213405e6350243954",
        "name" : "Green",
        "description" : "Default green stylesheet",
        "backgroundColor" : "#B3DAC1",
        "fontColor" : "#051F24",
        "createdBy" : "5b1fa75598a9cb165a5fa758",
        "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
        "createdAt" : "2023-09-04T11:19:36.461+0000",
        "updatedAt" : "2023-09-04T11:19:36.461+0000",
        "types" : [
            "practice", "course", "lesson"
        ]
    },    
    {
        "_id" : "6405914213405e6350213950",
        "name" : "Trainify",
        "description" : "Default trainify stylesheet",
        "backgroundColor" : "#DDF0F0",
        "fontColor" : "#051F24",
        "createdBy" : "5b1fa75598a9cb165a5fa758",
        "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
        "createdAt" : "2023-09-04T11:19:36.461+0000",
        "updatedAt" : "2023-09-04T11:19:36.461+0000",
        "types" : [
            "practice", "course", "lesson"
        ]
    },       
    {
        "_id" : "64059a4213e05e6350213d50",
        "name" : "Grey",
        "description" : "Default grey stylesheet",
        "backgroundColor" : "#E6E4EA",
        "fontColor" : "#051F24",
        "createdBy" : "5b1fa75598a9cb165a5fa758",
        "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
        "createdAt" : "2023-09-04T11:19:36.461+0000",
        "updatedAt" : "2023-09-04T11:19:36.461+0000",
        "types" : [
            "practice", "course", "lesson"
        ]
    },                            

  ]
}

export const populate = ({ iter, }) => {
  let $item = JSON.parse(JSON.stringify({ ...iter }))
  return $item
}