import { populates, data as db } from './index';

export const data = {
  claims: [
    {
      "_id" : "64f707a1e5d622569fa260c8",
      "primary": {
        "common": {
          "practice": "64f707a1e5d622569fa260c8",
          "answer": "true"
        },
        "items" : [
          {
            "language" : "5e2313693993211e8128b315",
            "entry" : {
              "title" : "Claim 1",
              "explanation" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            },
            "_id" : "64ff0a8e3b6488ada5226c8a"
          },
          {
            "language" : "5e2302d95187570c9c399dd3",
            "entry" : {
              "title" : "Claim 1",
              "explanation" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            },
            "_id" : "64ff0a8e3b6488ada5226c8b"
          }
        ],        
      },
      "createdBy" : "5b1fa75598a9cb165a5fa758",
      "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
      "createdAt" : "2023-05-05T10:49:05.577+0000",
      "updatedAt" : "2023-08-11T12:39:42.450+0000",
    },
    {
      "_id" : "64f707a1e5d6205a9aa260c8",
      "primary": {
        "common": {
          "answer": "false"
        },
        "items" : [
          {
            "language" : "5e2313693993211e8128b315",
            "entry" : {
              "title" : "Claim 2",
              "explanation" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            },
            "_id" : "64ff0a8e3b6488ada5226c8a"
          },
          {
            "language" : "5e2302d95187570c9c399dd3",
            "entry" : {
              "title" : "Claim 2",
              "explanation" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            },
            "_id" : "64ff0a8e3b6488ada5226c8b"
          }
        ],        
      },      
      "createdBy" : "5b1fa75598a9cb165a5fa758",
      "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
      "createdAt" : "2023-02-03T10:49:05.577+0000",
      "updatedAt" : "2023-05-04T12:39:42.450+0000"
    }    
  ]
}

export const populate = ({ iter, }) => {
  let $item = JSON.parse(JSON.stringify({ ...iter }))

  let { languages, users, practices } = db;
  languages = languages.map(l=>populates.language({ iter: l }))
  // users = users.map(l=>populates.user({ iter: l }))
  practices = practices.map(l=>populates.practice({ iter: l }))

  $item.primary.items = $item.primary.items.map(t=>{ 
    return {
      ...t, 
      language: languages.find(j=> { return j._id === t.language  }) 
    }
  })

  $item.primary.common.practice = practices.find(u=>u._id === $item.primary.common.practice)
  $item.lastUpdatedBy = users.find(u=>u._id === $item.lastUpdatedBy)
  $item.createdBy = users.find(u=>u._id === $item.createdBy)

  return $item
}