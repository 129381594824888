import mock from './mock'
import { paginateArray, sortCompare, delay, getObjectId } from './utils'

import { data, populates } from './data'


mock.onGet('/mocks/lessons/index').reply(async config => {
  const { q = '', perPage = 10, page = 1, } = config

  const $courses = data.courses.map(e=> populates.course({ iter: e, }) )

  const queryLowered = q.toLowerCase()
  const filteredData = data.lessons
    .map(lesson=>populates.lesson({ iter: lesson }))
    .map(e=>{
        // if (e.publish.status !== "published") return e
        const $course = $courses.find(a=>a.lessons.find(l=>l._id === e._id) )
        if ($course && e.publish.status === "published"){
          e.index = $course.lessons.findIndex(l=>l._id === e._id) + 1
        }
        e.primary.common.course = $course
        return e
      })    
    .filter(
      lesson =>
        lesson?._id?.toLowerCase().includes(queryLowered) ||
        lesson?.name?.toLowerCase().includes(queryLowered) ||
        lesson?.createdAt?.toLowerCase().includes(queryLowered)
    )
    .sort(sortCompare('_id'))
    .reverse()

  await delay(1000)

  return [
    200, {
      lessons: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

mock.onGet('/mocks/lessons/new').reply(async config => {
  await delay(1000)

  const $courses = data.courses.map(e=> populates.course({ iter: e, }) )
  const $languages = data.languages.map(e=> populates.language({ iter: e, }) )
  const $stylesheets = data.stylesheets.map(e=> populates.stylesheet({ iter: e, }) )
                       .filter(a=>a.types.includes("lesson") )
  const $assets = data.assets.map(e=> populates.asset({ iter: e, }) )
                  // .filter(a=>a.category === "image:background:lesson" )

  return [200, { middlewares: { 
    languages: $languages,
    stylesheets: $stylesheets,
    assets: $assets,
    courses: $courses,
  } }]
})

mock.onGet('/mocks/lessons/edit').reply(async config => {
  const { id } = config
  const lesson = data.lessons.find(i => i._id === id)
  if (!lesson) return [404, { msg: "Lesson not found" }]

  const $lesson = populates.lesson({ iter: lesson })
  const $lesson_course = data.courses.map(e=> populates.course({ iter: e, }) )
                         .find(a=>a.lessons.find(l=>l._id === $lesson._id) )

  $lesson.primary.common.course = $lesson_course
  // console.log("$lesson_course", $lesson_course)

  const $courses = data.courses.map(e=> populates.course({ iter: e, }) )
  const $languages = data.languages.map(e=> populates.language({ iter: e, }) )
  const $stylesheets = data.stylesheets.map(e=> populates.stylesheet({ iter: e, }) )
                       .filter(a=>a.types.includes("lesson") )
  const $assets = data.assets.map(e=> populates.asset({ iter: e, }) )
                  // .filter(a=>a.category === "image:background:lesson" )

  await delay(1000)

  return [200, { lesson: $lesson, middlewares: { 
    languages: $languages,
    stylesheets: $stylesheets,
    assets: $assets,
    courses: $courses,
  } }]
})

mock.onPost('/mocks/lessons/create').reply(async config => {
  const lesson = JSON.parse(config.data)

  const $lesson = {
    _id: getObjectId(),
    createdAt : "2023-10-12T10:58:29.022+0000",
    updatedAt : "2023-10-12T10:57:09.839+0000",    
    createdBy: "5b1fa75598a9cb165a5fa758",
    lastUpdatedBy: "5b1fa75598a9cb165a5fa758",

    primary: {
      items: Object.entries(lesson.items).map(e=>{
        return { language: e[0], entry: e[1] }
      }),      
      common: {
        // course: lesson.course,  
        duration: lesson.duration,  
        // assets: {
        //   image: lesson.image
        // },        
        // stylesheet: lesson.stylesheet,
      },
    },
    elements: lesson.elements.map(e=>{
      return {
        category: e.category,
        common: e.common,
        items: Object.entries(e.items).map(e=>{
          return { language: e[0], entry: e[1] }
        })        
      }
    }),    
    publish: {
      status: lesson.status,
    }    
  }

  if (lesson.course){
    const $course = data.courses.find(c=>c._id === lesson.course)
    $course.lessons.push($lesson._id)
  }

  data.lessons.unshift($lesson)

  const $log = {
    "_id": getObjectId(),
    "target": $lesson._id,
    "model": "Lesson",
    "action": "primary:create:lesson",
    "data": $lesson
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Lesson '${$lesson._id}' has been successfully created`, action: "primary:create:lesson", lesson: $lesson, }]
})

mock.onPost('/mocks/lessons/update').reply(async config => {
  const lesson = JSON.parse(config.data)

  // console.log("lesson", lesson)

  let $lesson = data.lessons.find(i => i._id === lesson?._id)
  if (!$lesson) return [404, { msg: "Lesson not found" }]

  data.lessons = data.lessons.filter(u=>u._id !== lesson?._id)

  $lesson = { ...$lesson, 
    primary: {
      ...$lesson.primary,
      common: {
        ...$lesson.common,
        // course: lesson.course,
        duration: lesson.duration,
        // assets: {
        //   image: lesson.image
        // },
        // stylesheet: lesson.stylesheet,
      },
      items: Object.entries(lesson.items).map(e=>{
        return { language: e[0], entry: e[1] }
      })
    },
    elements: lesson.elements.map(e=>{
      return {
        category: e.category,
        common: e.common,
        items: Object.entries(e.items).map(e=>{
          return { language: e[0], entry: e[1] }
        })        
      }
    }),
    publish: {
      status: lesson.status,
    }
  }

  // console.log("$lesson", $lesson)

  data.lessons.unshift($lesson)

  const $log = {
    "_id": getObjectId(),
    "target": $lesson._id,
    "model": "Lesson",
    "action": "primary:update:lesson",
    "data": $lesson
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Lesson '${$lesson._id}' has been successfully updated`, action: "primary:update:lesson", lesson: $lesson, }]
})

mock.onGet('/mocks/lessons/logs').reply(config => {
  const { q = '', perPage = 10, page = 1, target = null } = config

  const queryLowered = q.toLowerCase()
  const filteredData = data.logs
    .filter(l=>l.target === target )
    .filter(l=>l?.action?.toLowerCase().includes(queryLowered))
    .sort(sortCompare('_id'))
    .reverse()

  return [
    200, {
      logs: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

mock.onGet('/mocks/lessons/usage').reply(config => {
  const { q = '', perPage = 10, page = 1, target = null } = config

  const users = data.users.filter(u=>u.lesson === target)

  return [
    200, {
      usage: {
        users: users
      },
      total: 0
    }
  ]
})

mock.onDelete('/mocks/lessons/delete').reply(async config => {
  const { _id } = config

  const lessonIndex = data.lessons.findIndex(t => t._id === _id)
  const $lesson = data.lessons.splice(lessonIndex, 1)
  
  const $log = {
    "_id": getObjectId(),
    "target": $lesson._id,
    "model": "Lesson",
    "action": "primary:delete:lesson",
    "data": $lesson
  }

  data.logs.unshift($log)

  await delay(1000)

  return [200, { msg: `Lesson '${_id}' has been successfully deleted`, action: "primary:delete:lesson" }]
})