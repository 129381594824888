import mock from './mock'
import { paginateArray, sortCompare, delay } from './utils'

import { data, populates } from './data'


const $PRIVILEGES = [ 
  { id: "access:admin", name: "Administrative access" }, 
  { id: "custom:permissions", name: "Custom permissions" }
]

mock.onGet('/mocks/roles/index').reply(async config => {
  const { q = '', perPage = 10, page = 1, } = config

  const queryLowered = q.toLowerCase()
  const filteredData = data.roles
    .map(role=>populates.role({ iter: role }))
    .filter(
      role =>
        role?._id?.toLowerCase().includes(queryLowered) ||
        role?.name?.toLowerCase().includes(queryLowered) ||
        role?.createdAt?.toLowerCase().includes(queryLowered) ||
        role?.code?.toLowerCase().includes(queryLowered)
    )
    .sort(sortCompare('_id'))
    .reverse()

  await delay(1000)

  return [
    200, {
      roles: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

mock.onGet('/mocks/roles/new').reply(async config => {
  await delay(1000)

  return [200, { middlewares: { 
    privileges: $PRIVILEGES
  } }]
})

mock.onGet('/mocks/roles/edit').reply(async config => {
  const { id } = config
  const role = data.roles.find(i => i._id === id)
  if (!role) return [404, { msg: "Role not found" }]

  const $role = populates.role({ iter: role })

  await delay(1000)

  return [200, { role: $role, middlewares: { 
    privileges: $PRIVILEGES
  } }]
})

mock.onPost('/mocks/roles/create').reply(async config => {
  const role = JSON.parse(config.data)

  const $role = {
    _id: "5b1fa75598a9cb165bbfa758",
    name: role.name,
    code: role.code,
    privileges: role.privileges,
    createdAt : "2023-10-12T10:58:29.022+0000",
    updatedAt : "2023-10-12T10:57:09.839+0000",    
  }

  data.roles.unshift($role)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": "5b1fa75598a9cb165bbfa758",
    "model": "Role",
    "action": "primary:create:role",
    "data": $role
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Role '${$role._id}' has been successfully created`, action: "primary:create:role", role: $role, }]
})

mock.onPost('/mocks/roles/update').reply(async config => {
  const role = JSON.parse(config.data)

  let $role = data.roles.find(i => i._id === role?._id)
  if (!$role) return [404, { msg: "Role not found" }]

  data.roles = data.roles.filter(u=>u._id !== role?._id)

  $role = { ...$role, 
    name: role.name,
    code: role.code,
    privileges: role.privileges,
  }

  data.roles.unshift($role)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $role._id,
    "model": "Role",
    "action": "primary:update:role",
    "data": $role
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Role '${$role._id}' has been successfully updated`, action: "primary:update:role", role: $role, }]
})

mock.onGet('/mocks/roles/logs').reply(config => {
  const { q = '', perPage = 10, page = 1, target = null } = config

  const queryLowered = q.toLowerCase()
  const filteredData = data.logs
    .filter(l=>l.target === target )
    .filter(l=>l?.action?.toLowerCase().includes(queryLowered))
    .sort(sortCompare('_id'))
    .reverse()

  return [
    200, {
      logs: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

mock.onGet('/mocks/roles/usage').reply(config => {
  const { q = '', perPage = 10, page = 1, target = null } = config

  const users = data.users.filter(u=>u.role === target)

  return [
    200, {
      usage: {
        users: users
      },
      total: 0
    }
  ]
})

mock.onDelete('/mocks/roles/delete').reply(async config => {
  const { _id } = config

  const roleIndex = data.roles.findIndex(t => t._id === _id)
  const $role = data.roles.splice(roleIndex, 1)
  
  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $role._id,
    "model": "Role",
    "action": "primary:delete:role",
    "data": $role
  }

  data.logs.unshift($log)

  await delay(1000)

  return [200, { msg: `Role '${_id}' has been successfully deleted`, action: "primary:delete:role" }]
})