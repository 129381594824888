export const data = {
  logs: [
    {
        "_id": "5b3cc254e12cf13b12cc181b",
        "target": "5b1fa75598a9cb165a5fa758",
        "model": "User",
        "action": "primary:create:user:account",
        "data": { }
    },
    {
        "_id": "5b3cb254e12ef13be2cc181b",
        "target": "5b1fa75598a9cb165a5fa758",
        "model": "User",
        "action": "primary:update:user:account",
        "data": { }
    },    
  ]
}

export const populate = ({ iter, }) => {
  let $item = JSON.parse(JSON.stringify({ ...iter }))
  return $item
}