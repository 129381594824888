import { populates, data as db } from './index';

export const data = {
  lessons: [
    {
      "_id" : "64f707a1eacb221c9fa260c8",
      "primary": {
        "common": {
          "duration": 5,
          "stylesheet" : "64f6fac65b96fa2292f725c7",
          "assets" : {
            "image" : "64f70761e5c620569fa26056"
          },
        },
        "items" : [
          {
            "language" : "5e2313693993211e8128b315",
            "entry" : {
              "title" : "Lesson title",
              "description" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            },
            "_id" : "64ff0a8e3b6488ada5226c8a"
          },
          {
            "language" : "5e2302d95187570c9c399dd3",
            "entry" : {
              "title" : "Lesson title",
              "description" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            },
            "_id" : "64ff0a8e3b6488ada5226c8b"
          }
        ],        
      },
      "createdBy" : "5b1fa75598a9cb165a5fa758",
      "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
      "elements" : [
        {
          "category": "title",
          "items" : [
            {
              "language" : "5e2313693993211e8128b315",
              "entry" : {
                "contents" : "Title element",
              },
              "_id" : "64ff0a8e3b6488ada5226c8a"
            },
            {
              "language" : "5e2302d95187570c9c399dd3",
              "entry" : {
                "contents" : "Title element",
              },
              "_id" : "64ff0a8e3b6488ada5226c8b"
            }
          ],        
        },
        {
          "category": "text",
          "items" : [
            {
              "language" : "5e2313693993211e8128b315",
              "entry" : {
                "contents" : "Text element",
              },
              "_id" : "64ff0a8e3b6488ada5226c8a"
            },
            {
              "language" : "5e2302d95187570c9c399dd3",
              "entry" : {
                "contents" : "Text element",
              },
              "_id" : "64ff0a8e3b6488ada5226c8b"
            }
          ],        
        },
        {
          "category": "image",
          "common": {
            "assets" : {
              "image" : "5e2301d92487575a03342ad9"
            },
          },          
          "items" : [
            {
              "language" : "5e2313693993211e8128b315",
              "entry" : {
                "description" : "Image description",
                "alt" : "Image alt",
              },
              "_id" : "64ff0a8e3b6488ada5226c8a"
            },
            {
              "language" : "5e2302d95187570c9c399dd3",
              "entry" : {
                "description" : "Image description",
                "alt" : "Image alt",
              },
              "_id" : "64ff0a8e3b6488ada5226c8b"
            }
          ],        
        },     
        {
          "category": "bullet",
          "items" : [
            {
              "language" : "5e2313693993211e8128b315",
              "entry" : {
                "title" : "Bullet element",
                "points": [ 
                  { title: "Point 1" },
                  { title: "Point 2" },
                  { title: "Point 3" }
                ]
              },
              "_id" : "64ff0a8e3b6488ada5226c8a"
            },
            {
              "language" : "5e2302d95187570c9c399dd3",
              "entry" : {
                "title" : "Bullet element",
                "points": [ 
                  { title: "Point 1" },
                  { title: "Point 2" },
                  { title: "Point 3" }
                ]
              },
              "_id" : "64ff0a8e3b6488ada5226c8b"
            }
          ],
        },
        {
          "category": "accordion",
          "items" : [
            {
              "language" : "5e2313693993211e8128b315",
              "entry" : {
                "points": [ 
                  {
                    "title" : "Accordion title",
                    "description" : "Accordion description",
                  },
                ]
              },
              "_id" : "64ff0a8e3b6488ada5226c8a"
            },
            {
              "language" : "5e2302d95187570c9c399dd3",
              "entry" : {
                "points": [ 
                  {
                    "title" : "Accordion title",
                    "description" : "Accordion description",
                  },
                ]
              },
              "_id" : "64ff0a8e3b6488ada5226c8b"
            }
          ],
        },  
        {
          "category": "summary",
          "items" : [
            {
              "language" : "5e2313693993211e8128b315",
              "entry" : {
                "points": [ 
                  {
                    "title" : "Summary title",
                  },
                ]
              },
              "_id" : "64ff0a8e3b6488ada5226c8a"
            },
            {
              "language" : "5e2302d95187570c9c399dd3",
              "entry" : {
                "points": [
                  {
                    "title" : "Summary title",
                  },
                ]
              },
              "_id" : "64ff0a8e3b6488ada5226c8b"
            }
          ],
        },

      ],
      "createdAt" : "2023-05-05T10:49:05.577+0000",
      "updatedAt" : "2023-08-11T12:39:42.450+0000",
      "publish" : {
        "status" : "published"
      }
    },
    {
      "_id" : "64f707a1e5d6205a9fa260c8",
      "primary": {
        "common": {
          "duration": 3,
          "stylesheet" : "64f6fac65b96fa2292f725c7",
          "assets" : {
            "image" : "64f70761e5c620569fa26056"
          },
        },
        "items" : [
          {
            "language" : "5e2313693993211e8128b315",
            "entry" : {
              "title" : "Lesson title",
              "description" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            },
            "_id" : "64ff0a8e3b6488ada5226c8a"
          },
          {
            "language" : "5e2302d95187570c9c399dd3",
            "entry" : {
              "title" : "Lesson title",
              "description" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            },
            "_id" : "64ff0a8e3b6488ada5226c8b"
          }
        ],        
      },      
      "createdBy" : "5b1fa75598a9cb165a5fa758",
      "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
      "elements" : [

      ],
      "createdAt" : "2023-02-03T10:49:05.577+0000",
      "updatedAt" : "2023-05-04T12:39:42.450+0000",
      "publish" : {
        "status" : "draft"
      }
    },
    {
      "_id" : "64f707a1ecd720aa9fa260cc",
      "primary": {
        "common": {
          "stylesheet" : "64f6fac65b96fa2292f725c7",
          "assets" : {
            "image" : "64f70761e5c620569fa26056"
          },
        },
        "items" : [
          {
            "language" : "5e2313693993211e8128b315",
            "entry" : {
              "title" : "Lesson title",
              "description" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            },
            "_id" : "64ff0a8e3b6488ada5226c8a"
          },
          {
            "language" : "5e2302d95187570c9c399dd3",
            "entry" : {
              "title" : "Lesson title",
              "description" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            },
            "_id" : "64ff0a8e3b6488ada5226c8b"
          }
        ],        
      },      
      "createdBy" : "5b1fa75598a9cb165a5fa758",
      "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
      "elements" : [

      ],
      "createdAt" : "2023-06-13T10:49:05.577+0000",
      "updatedAt" : "2023-07-01T12:39:42.450+0000",
      "publish" : {
        "status" : "draft"
      }
    }    
  ]
}

export const populate = ({ iter, }) => {
  let $item = JSON.parse(JSON.stringify({ ...iter }))

  let { languages, users, stylesheets, courses } = db;
  languages = languages.map(l=>populates.language({ iter: l }))
  // users = users.map(l=>populates.user({ iter: l }))
  stylesheets = stylesheets.map(l=>populates.stylesheet({ iter: l }))
  // courses = courses.map(l=>populates.course({ iter: l }))

  $item.elements = $item.elements.map(k=>{ 
    return {
      ...k, 
      items: k.items.map(t=>{ 
        return {
          ...t, 
          language: languages.find(j=> { return j._id === t.language  }) 
        }
      })
    }  
  })

  $item.primary.items = $item.primary.items.map(t=>{ 
    return {
      ...t, 
      language: languages.find(j=> { return j._id === t.language  }) 
    }
  })  

  // $item.primary.common.course = courses.find(u=>u._id === $item.primary.common.course)
  $item.primary.common.stylesheet = stylesheets.find(u=>u._id === $item.primary.common.stylesheet)
  $item.lastUpdatedBy = users.find(u=>u._id === $item.lastUpdatedBy)
  $item.createdBy = users.find(u=>u._id === $item.createdBy)

  return $item
}