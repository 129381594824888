export const data = {
  assets: [
    {
      "_id" : "5e231363399321148128b316",
      "name" : "Finnish language icon",
      "description" : "Finnish language icon",
      "createdAt" : "2018-06-12T10:58:29.022+0000",
      "updatedAt" : "2023-09-06T10:57:09.839+0000",
      "createdBy" : "5b1fa75598a9cb165a5fa758",
      "file": {
        "path": require('@src/assets/images/languages/finnish.svg').default
      },
      "category" : "image:logo:language"
    },
    {
      "_id" : "5e2302d95487570c93392dd9",
      "name" : "English language icon",
      "description" : "English language icon",
      "createdAt" : "2018-06-12T10:58:29.022+0000",
      "updatedAt" : "2023-09-06T10:57:09.839+0000",
      "createdBy" : "5b1fa75598a9cb165a5fa758",
      "file": {
        "path": require('@src/assets/images/languages/english.svg').default
      },
      "category" : "image:logo:language"      
    },
    {
      "_id" : "5e2301d91487575c93392dd9",
      "name" : "HP background",
      "description" : "Hygiene Passport background",
      "createdAt" : "2018-06-12T10:58:29.022+0000",
      "updatedAt" : "2023-09-06T10:57:09.839+0000",
      "createdBy" : "5b1fa75598a9cb165a5fa758",
      "file": {
        "path": require('@src/assets/images/patterns/pattern_HP.svg').default
      },
      "category" : "image:background:environment"      
    },
    {
      "_id" : "5e2301d94487575a93342dd9",
      "name" : "AP background",
      "description" : "Alcohol Passport background",
      "createdAt" : "2018-06-12T10:58:29.022+0000",
      "updatedAt" : "2023-09-06T10:57:09.839+0000",
      "createdBy" : "5b1fa75598a9cb165a5fa758",
      "file": {
        "path": require('@src/assets/images/patterns/pattern_AP.svg').default
      },
      "category" : "image:background:environment"      
    },
    {
      "_id" : "5e2301d92487575a03342ad9",
      "name" : "Safety Card background",
      "description" : "Safety Card background",
      "createdAt" : "2018-06-12T10:58:29.022+0000",
      "updatedAt" : "2023-09-06T10:57:09.839+0000",
      "createdBy" : "5b1fa75598a9cb165a5fa758",
      "file": {
        "path": require('@src/assets/images/patterns/pattern_safety.svg').default
      },
      "category" : "image:background:environment"      
    }
  ]
}

export const populate = ({ iter, }) => {
  let $item = JSON.parse(JSON.stringify({ ...iter }))
  return $item
}

