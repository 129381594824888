import mock from './mock'
import { paginateArray, sortCompare, delay } from './utils'

import { data, populates } from './data'

import jwt from 'jsonwebtoken'


mock.onPost('/mocks/authenticate').reply(async config => {
  const token = config.headers.Authorization.split(" ")[1]

  const decode = jwt.verify(token, '12345')

  let user = data.users.find(i => i._id === decode?.id)
  if (!user) return [404, { msg: "User not found" }]

  const $user = populates.user({ iter: user })

  // data.users = data.users.filter(u=>u._id !== decode.id)

  // $user = { ...$user, 
  //   lastActiveAt: new Date(),
  // }

  // data.users.unshift($user)  

  const $environments = data.environments.map(e=> populates.environment({ iter: e, }) )

  await delay(1000)

  return [201, { 
    msg: `User '${$user._id}' successfully authenticate`, 
    action: "primary:update:user:authenticate", 
    user: $user, 
    middlewares: {
      environments: $environments, 
    }
  }]
})

mock.onPost('/mocks/account/update/environment/active').reply(async config => {
  const token = config.headers.Authorization.split(" ")[1]

  const decode = jwt.verify(token, '12345')

  let user = data.users.find(i => i._id === decode?.id)
  if (!user) return [404, { msg: "User not found" }]


  const $config = JSON.parse(config.data)
  const { environmentId } = $config

  const environment = data.environments.find(i => i._id === environmentId)
  if (!environment) return [404, { msg: "Environment not found" }]


  data.users = data.users.filter(u=>u._id !== decode.id)

  user = { ...user, 
    active: {
      ...user.active,
      environment: environment._id
    }
  }

  data.users.unshift(user)  


  const $user = populates.user({ iter: user })

  await delay(1000)

  return [201, { 
    msg: `User '${$user._id}' successfully updated active environment`, 
    action: "primary:update:user:environment:active", 
    user: $user, 
  }]
})



mock.onPost('/mocks/account/update/languages/active').reply(async config => {
  const token = config.headers.Authorization.split(" ")[1]

  const decode = jwt.verify(token, '12345')

  let user = data.users.find(i => i._id === decode?.id)
  if (!user) return [404, { msg: "User not found" }]


  const $config = JSON.parse(config.data)
  const { primary, secondary } = $config
  let planguage, slanguage

  if (primary){
    planguage = data.languages.find(i => i._id === primary)
    if (!planguage) return [404, { msg: "Primary language not found" }]    
  }
  if (secondary){
    slanguage = data.languages.find(i => i._id === secondary)
    if (!slanguage) return [404, { msg: "Secondary language not found" }]        
  }

  data.users = data.users.filter(u=>u._id !== decode.id)

  user = { ...user, 
    active: {
      ...user.active,
      languages: {
        primary: planguage?._id,
        secondary: slanguage?._id,
      }
    }
  }

  data.users.unshift(user)  


  const $user = populates.user({ iter: user })

  await delay(1000)

  return [201, { 
    msg: `User '${$user._id}' successfully updated active languages`, 
    action: "primary:update:user:languages:active", 
    user: $user, 
  }]
})








mock.onGet('/mocks/users/index').reply(async config => {
  const { q = '', perPage = 10, page = 1, } = config



  const queryLowered = q.toLowerCase()
  const filteredData = data.users
    .map(user=>populates.user({ iter: user }))
    .filter(
      user =>
        user?.lastUpdatedBy?.email?.toLowerCase().includes(queryLowered) ||
        user?.lastUpdatedBy?.profile?.name?.toLowerCase().includes(queryLowered) ||
        user?.profile?.name?.toLowerCase().includes(queryLowered) ||
        user?.email?.toLowerCase().includes(queryLowered) ||
        user?.role?.name?.toLowerCase().includes(queryLowered)
    )
    .sort(sortCompare('_id'))
    .reverse()

  await delay(1000)

  return [
    200, {
      users: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

mock.onGet('/mocks/users/new').reply(async config => {
  const $environments = data.environments.map(e=> populates.environment({ iter: e, }) )
  const $roles = data.roles.map(e=> populates.role({ iter: e, }) )
  const $organisations = data.organisations.map(e=> populates.organisation({ iter: e, }) )
  const $languages = data.languages.map(e=> populates.language({ iter: e, }) )

  await delay(1000)

  return [200, { middlewares: { 
    environments: $environments, 
    roles: $roles,
    organisations: $organisations,
    languages: $languages
  } }]
})

mock.onGet('/mocks/users/edit').reply(async config => {
  // console.log("config", config)

  const { id } = config
  const user = data.users.find(i => i._id === id)
  if (!user) return [404, { msg: "User not found" }]

  const $user = populates.user({ iter: user })

  const $environments = data.environments.map(e=> populates.environment({ iter: e, }) )
  const $roles = data.roles.map(e=> populates.role({ iter: e, }) )
  const $organisations = data.organisations.map(e=> populates.organisation({ iter: e, }) )
  const $languages = data.languages.map(e=> populates.language({ iter: e, }) )

  await delay(1000)

  // return [401, {}]

  return [200, { user: $user, middlewares: { 
    environments: $environments, 
    roles: $roles,
    organisations: $organisations,
    languages: $languages
  } }]
})

mock.onPost('/mocks/users/create').reply(async config => {
  const user = JSON.parse(config.data)

  const $user = {
    _id: "5b1fa75598a9cb165bbfa758",
    role: user.role,
    email: user.email,
    profile: { name: user.name, },
    password: user.password,
    organisation: user.organisation,
    createdAt : "2023-10-12T10:58:29.022+0000",
    updatedAt : "2023-10-12T10:57:09.839+0000",    
  }

  if (user.privileges){
    $user.privileges = Object.entries(user.privileges.environment).map(p=>{
      const scopes = Object.entries(p[1].languages).filter(l=>l[1]).map(l=>({ language: l[0] }))
      return { typ: "environment", target: p[0], scopes: scopes }
    })
  }

  data.users.unshift($user)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": "5b1fa75598a9cb165bbfa758",
    "model": "User",
    "action": "primary:create:user:account",
    "data": $user
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `User '${$user._id}' has been successfully created`, action: "primary:create:user:account", user: $user, }]
})

mock.onPost('/mocks/users/update').reply(async config => {
  const user = JSON.parse(config.data)

  let $user = data.users.find(i => i._id === user?._id)
  if (!$user) return [404, { msg: "User not found" }]

  data.users = data.users.filter(u=>u._id !== user?._id)

  $user = { ...$user, 
    role: user.role,
    email: user.email,
    profile: { name: user.name, },
    organisation: user.organisation,  
  }

  if (user.privileges){
    $user.privileges = Object.entries(user.privileges.environment).map(p=>{
      const scopes = Object.entries(p[1].languages).filter(l=>l[1]).map(l=>({ language: l[0] }))
      return { typ: "environment", target: p[0], scopes: scopes }
    })
  }

  data.users.unshift($user)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $user._id,
    "model": "User",
    "action": "primary:update:user:account",
    "data": $user
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `User '${$user._id}' has been successfully updated`, action: "primary:update:user:account", user: $user, }]
})


mock.onPost('/mocks/users/update/environment').reply(async config => {
  const user = JSON.parse(config.data)

  let $user = data.users.find(i => i._id === user?._id)
  if (!$user) return [404, { msg: "User not found" }]

  data.users = data.users.filter(u=>u._id !== user?._id)

  $user = { ...$user, 
    active: { 
      ...$user.active,
      environment: user.environment 
    },
  }

  data.users.unshift($user)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $user._id,
    "model": "User",
    "action": "primary:update:user:environment:active",
    "data": $user
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `User '${$user._id}' active environment has been successfully updated`, action: "primary:update:user:environment:active", user: $user, }]
})


mock.onGet('/mocks/users/logs').reply(config => {
  const { q = '', perPage = 10, page = 1, target = null } = config

  const queryLowered = q.toLowerCase()
  const filteredData = data.logs
    .filter(l=>l.target === target )
    .filter(
      l =>
        l?.action?.toLowerCase().includes(queryLowered)
    )    
    .sort(sortCompare('_id'))
    .reverse()

  return [
    200, {
      logs: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

mock.onDelete('/mocks/users/delete').reply(async config => {
  const { _id } = config

  const userIndex = data.users.findIndex(t => t._id === _id)
  const $user = data.users.splice(userIndex, 1)
  
  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $user._id,
    "model": "User",
    "action": "primary:delete:user",
    "data": $user
  }

  data.logs.unshift($log)

  await delay(1000)

  return [200, { msg: `User '${_id}' has been successfully deleted`, action: "primary:delete:user:account" }]
})

mock.onPost('/mocks/users/update/password').reply(async config => {
  const user = JSON.parse(config.data)

  let $user = data.users.find(i => i._id === user?._id)
  if (!$user) return [404, { msg: "User not found" }]

  data.users = data.users.filter(u=>u._id !== user?._id)

  $user = { ...$user, 
    password: user.password,
  }

  data.users.unshift($user)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $user._id,
    "model": "User",
    "action": "primary:update:user:password",
    "data": $user
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `User '${$user._id}' has been successfully updated`, action: "primary:update:user:password", user: $user, }]
})
