import mock from './mock'
import { paginateArray, sortCompare, delay, getObjectId } from './utils'

import { data, populates } from './data'


mock.onGet('/mocks/claims/index').reply(async config => {
  const { q = '', perPage = 10, page = 1, } = config

  const $claims = data.claims.map(e=> populates.claim({ iter: e, }) )
  const $environments = data.environments.map(e=> populates.environment({ iter: e, }) )

  const queryLowered = q.toLowerCase()
  const filteredData = data.claims
    .map(claim=>{
      if (claim.publish?.status === "published"){
        const $environment = $environments.find(e=>e.claims && e.claims.find(c=>c?.target?._id === claim._id))
        if ($environment) claim.index = $environment.claims.findIndex(c=>c?.target?._id === claim._id) + 1
      }
      return claim
    })  
    .map(claim=>populates.claim({ iter: claim }))
    .filter(
      claim =>
        claim?._id?.toLowerCase().includes(queryLowered) ||
        claim?.name?.toLowerCase().includes(queryLowered) ||
        claim?.createdAt?.toLowerCase().includes(queryLowered)
    )
    .sort(sortCompare('_id'))
    .reverse()

  await delay(1000)

  return [
    200, {
      claims: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

mock.onGet('/mocks/claims/new').reply(async config => {
  await delay(1000)

  const $practices = data.practices.map(e=> populates.practice({ iter: e, }) )
  const $languages = data.languages.map(e=> populates.language({ iter: e, }) )
  const $stylesheets = data.stylesheets.map(e=> populates.stylesheet({ iter: e, }) )
                       .filter(a=>a.types.includes("claim") )
  const $assets = data.assets.map(e=> populates.asset({ iter: e, }) )
                  .filter(a=>a.category === "image:background:claim" )

  return [200, { middlewares: { 
    languages: $languages,
    stylesheets: $stylesheets,
    assets: $assets,
    practices: $practices,
  } }]
})

mock.onGet('/mocks/claims/edit').reply(async config => {
  const { id } = config
  const claim = data.claims.find(i => i._id === id)
  if (!claim) return [404, { msg: "Claim not found" }]

  const $claim = populates.claim({ iter: claim })

  const $practices = data.practices.map(e=> populates.practice({ iter: e, }) )
  const $languages = data.languages.map(e=> populates.language({ iter: e, }) )
  const $stylesheets = data.stylesheets.map(e=> populates.stylesheet({ iter: e, }) )
                       .filter(a=>a.types.includes("claim") )
  const $assets = data.assets.map(e=> populates.asset({ iter: e, }) )
                  .filter(a=>a.category === "image:background:claim" )

  await delay(1000)

  return [200, { claim: $claim, middlewares: { 
    languages: $languages,
    stylesheets: $stylesheets,
    assets: $assets,
    practices: $practices,
  } }]
})

mock.onPost('/mocks/claims/create').reply(async config => {
  const claim = JSON.parse(config.data)

  const $claim = {
    _id: getObjectId(),
    createdAt : "2023-10-12T10:58:29.022+0000",
    updatedAt : "2023-10-12T10:57:09.839+0000",    
    createdBy: "5b1fa75598a9cb165a5fa758",
    lastUpdatedBy: "5b1fa75598a9cb165a5fa758",

    primary: {
      items: Object.entries(claim.items).map(e=>{
        return { language: e[0], entry: e[1] }
      }),      
      common: {
        answer: claim.answer,
        practice: claim.practice,
      },
    }  
  }

  data.claims.unshift($claim)

  const $log = {
    "_id": getObjectId(),
    "target": $claim._id,
    "model": "Claim",
    "action": "primary:create:claim",
    "data": $claim
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Claim '${$claim._id}' has been successfully created`, action: "primary:create:claim", claim: $claim, }]
})

mock.onPost('/mocks/claims/update').reply(async config => {
  const claim = JSON.parse(config.data)

  let $claim = data.claims.find(i => i._id === claim?._id)
  if (!$claim) return [404, { msg: "Claim not found" }]

  data.claims = data.claims.filter(u=>u._id !== claim?._id)

  $claim = { ...$claim, 
    primary: {
      ...$claim.primary,
      common: {
        ...$claim.common,
        answer: claim.answer,
        practice: claim.practice,
      },
      items: Object.entries(claim.items).map(e=>{
        return { language: e[0], entry: e[1] }
      })
    }
  }

  data.claims.unshift($claim)

  const $log = {
    _id: getObjectId(),
    "target": $claim._id,
    "model": "Claim",
    "action": "primary:update:claim",
    "data": $claim
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Claim '${$claim._id}' has been successfully updated`, action: "primary:update:claim", claim: $claim, }]
})

mock.onGet('/mocks/claims/logs').reply(config => {
  const { q = '', perPage = 10, page = 1, target = null } = config

  const queryLowered = q.toLowerCase()
  const filteredData = data.logs
    .filter(l=>l.target === target )
    .filter(l=>l?.action?.toLowerCase().includes(queryLowered))
    .sort(sortCompare('_id'))
    .reverse()

  return [
    200, {
      logs: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

mock.onGet('/mocks/claims/usage').reply(config => {
  const { q = '', perPage = 10, page = 1, target = null } = config

  const users = data.users.filter(u=>u.claim === target)

  return [
    200, {
      usage: {
        users: users
      },
      total: 0
    }
  ]
})

mock.onDelete('/mocks/claims/delete').reply(async config => {
  const { _id } = config

  const claimIndex = data.claims.findIndex(t => t._id === _id)
  const $claim = data.claims.splice(claimIndex, 1)
  
  const $log = {
    _id: getObjectId(),
    "target": $claim._id,
    "model": "Claim",
    "action": "primary:delete:claim",
    "data": $claim
  }

  data.logs.unshift($log)

  await delay(1000)

  return [200, { msg: `Claim '${_id}' has been successfully deleted`, action: "primary:delete:claim" }]
})