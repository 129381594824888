import { populates, data as db } from './index';

export const data = {
  environments: [
    {
      "_id" : "64f707a1e50620169fa260c8",
      "primary": {
        "common": {
          "stylesheet" : "64f5bd48134d5e63d92d39b1",
          "assets" : {
            "image" : "5e2301d91487575c93392dd9"
          }
        },
        "items" : [
          {
            "language" : "5e2313693993211e8128b315",
            "entry" : {
              "title" : "Hygiene passport",
              "description" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
              "courses": {
                "description" : "courses Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
              },
              "practices": {
                "description" : "practices Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
              },
              "final": {
                "title" : "final Hygiene passport",
                "description" : "final Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
              },
            },
            "_id" : "64ff0a8e3b6488ada5226c8a"
          },
          {
            "language" : "5e2302d95187570c9c399dd3",
            "entry" : {
              "title" : "Hygiene passport",
              "description" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
              "courses": {
                "description" : "courses Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
              },
              "practices": {
                "description" : "practices Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
              },
              "final": {
                "title" : "final Hygiene passport",
                "description" : "final Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
              },           
            },
            "_id" : "64ff0a8e3b6488ada5226c8b"
          }
        ]        
      },
      "createdBy" : "5b1fa75598a9cb165a5fa758",
      "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
      "languages" : [
        { target: "5e2313693993211e8128b315", "publish" : { "status" : "published" } },
        { target: "5e2302d95187570c9c399dd3", "publish" : { "status" : "published" } },
      ],
      "courses" : [
        "64f707a1e5c620569fa260c8",
        "64f707a1e5d620569fa260c8"
      ],      
      "practices" : [
        { target: "64f707a1e5d622569fa260c8", "final" : { include: true, "proportion" : 10 } },
        { target: "64f707a1e5d6205a9aa260c8", "final" : { include: true, "proportion" : 90 } },
      ],
      "createdAt" : "2023-09-05T10:49:05.577+0000",
      "updatedAt" : "2023-09-11T12:39:42.450+0000",
      "publish" : {
        "status" : "published"
      }
    },
    {
      "_id" : "64f5cef0c062fcc020481df8",
      "primary": {
        "common": {
          "stylesheet" : "64f5ce56c062fcc020481d4e",
          "assets" : {
            "image" : "5e2301d94487575a93342dd9"
          }
        },
        "items" : [
          {
            "language" : "5e2313693993211e8128b315",
            "entry" : {
              "title" : "Alcohol passport",
              "description" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
              "courses": {
                "description" : "courses Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
              },
              "practices": {
                "description" : "practices Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
              },
              "final": {
                "title" : "final Alcohol passport",
                "description" : "final Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
              },
            },
            "_id" : "64ff0a8e3b6488ada5226c8a"
          },
          {
            "language" : "5e2302d95187570c9c399dd3",
            "entry" : {
              "title" : "Alcohol passport",
              "description" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
              "courses": {
                "description" : "courses Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
              },
              "practices": {
                "description" : "practices Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
              },
              "final": {
                "title" : "final Alcohol passport",
                "description" : "final Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
              },              
            },
            "_id" : "64ff0a8e3b6488ada5226c8b"
          }
        ]        
      },
      "createdBy" : "5b1fa75598a9cb165a5fa758",
      "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
      "languages" : [
        { target: "5e2313693993211e8128b315", "publish" : { "status" : "published" } },
        // { target: "5e2302d95187570c9c399dd3", "publish" : { "status" : "published" } },
      ],
      "practices" : [
        // "64f707a1e5d622569fa260c8",
        // "64f707a1e5d6205a9aa260c8"
      ],
      "createdAt" : "2023-09-05T10:49:05.577+0000",
      "updatedAt" : "2023-09-11T12:39:42.450+0000",
      "publish" : {
        "status" : "published"
      }
    }

  ],
}

export const populate = ({ iter,  }) => {
  let $item = JSON.parse(JSON.stringify({ ...iter }))

  let { languages, users, stylesheets, practices, assets, courses } = db;
  languages = languages.map(l=>populates.language({ iter: l }))
  // users = users.map(l=>populates.user({ iter: l }))
  stylesheets = stylesheets.map(l=>populates.stylesheet({ iter: l }))
  practices = practices.map(l=>populates.practice({ iter: l }))
  courses = courses.map(l=>populates.course({ iter: l }))


  $item.languages = $item.languages.map(l=>({ ...l, target: languages.find(k=>k._id === l.target) }))
  $item.primary.items = $item.primary.items.map(l=>({ ...l, language: languages.find(k=>k._id === l.language ) }))

  $item.primary.common.assets.image = assets.find(u=>u._id === $item.primary.common.assets.image)

  $item.primary.common.stylesheet = stylesheets.find(u=>u._id === $item.primary.common.stylesheet)
  $item.lastUpdatedBy = users.find(u=>u._id === $item.lastUpdatedBy)
  $item.createdBy = users.find(u=>u._id === $item.createdBy)

  if ($item.practices){
    $item.practices = $item.practices.map(l=>({
      ...l,
      target: { ...practices.find(u=>u._id === l.target) }
    })) 
  }  

  if ($item.courses){
    $item.courses = $item.courses.map(l=>({
      ...courses.find(u=>u._id === l)
    })) 
  }  

  return $item
}
