import mock from './mock'
import { paginateArray, sortCompare, delay } from './utils'

import { data, populates } from './data'


mock.onGet('/mocks/organisations/index').reply(async config => {
  const { q = '', perPage = 10, page = 1, } = config

  const queryLowered = q.toLowerCase()
  const filteredData = data.organisations
    .map(organisation=>populates.organisation({ iter: organisation }))
    .filter(
      organisation =>
        organisation?._id?.toLowerCase().includes(queryLowered) ||
        organisation?.name?.toLowerCase().includes(queryLowered) ||
        organisation?.createdAt?.toLowerCase().includes(queryLowered) ||
        organisation?.code?.toLowerCase().includes(queryLowered)
    )
    .sort(sortCompare('_id'))
    .reverse()

  await delay(1000)

  return [
    200, {
      organisations: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

mock.onGet('/mocks/organisations/new').reply(async config => {
  await delay(1000)

  return [200, { middlewares: { 
    
  } }]
})

mock.onGet('/mocks/organisations/edit').reply(async config => {
  const { id } = config
  const organisation = data.organisations.find(i => i._id === id)
  if (!organisation) return [404, { msg: "Organisation not found" }]

  const $organisation = populates.organisation({ iter: organisation })

  await delay(1000)

  return [200, { organisation: $organisation, middlewares: { 
    
  } }]
})

mock.onPost('/mocks/organisations/create').reply(async config => {
  const organisation = JSON.parse(config.data)

  const $organisation = {
    _id: "5b1fa75598a9cb165bbfa758",
    name: organisation.name,
    code: organisation.code,
    privileges: organisation.privileges,
    createdAt : "2023-10-12T10:58:29.022+0000",
    updatedAt : "2023-10-12T10:57:09.839+0000",    
  }

  data.organisations.unshift($organisation)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": "5b1fa75598a9cb165bbfa758",
    "model": "Organisation",
    "action": "primary:create:organisation",
    "data": $organisation
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Organisation '${$organisation._id}' has been successfully created`, action: "primary:create:organisation", organisation: $organisation, }]
})

mock.onPost('/mocks/organisations/update').reply(async config => {
  const organisation = JSON.parse(config.data)

  let $organisation = data.organisations.find(i => i._id === organisation?._id)
  if (!$organisation) return [404, { msg: "Organisation not found" }]

  data.organisations = data.organisations.filter(u=>u._id !== organisation?._id)

  $organisation = { ...$organisation, 
    name: organisation.name,
    code: organisation.code,
    privileges: organisation.privileges,
  }

  data.organisations.unshift($organisation)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $organisation._id,
    "model": "Organisation",
    "action": "primary:update:organisation",
    "data": $organisation
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Organisation '${$organisation._id}' has been successfully updated`, action: "primary:update:organisation", organisation: $organisation, }]
})

mock.onGet('/mocks/organisations/logs').reply(config => {
  const { q = '', perPage = 10, page = 1, target = null } = config

  const queryLowered = q.toLowerCase()
  const filteredData = data.logs
    .filter(l=>l.target === target )
    .filter(l=>l?.action?.toLowerCase().includes(queryLowered))
    .sort(sortCompare('_id'))
    .reverse()

  return [
    200, {
      logs: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

mock.onGet('/mocks/organisations/usage').reply(config => {
  const { q = '', perPage = 10, page = 1, target = null } = config

  const users = data.users.filter(u=>u.organisation === target)

  return [
    200, {
      usage: {
        users: users
      },
      total: 0
    }
  ]
})

mock.onDelete('/mocks/organisations/delete').reply(async config => {
  const { _id } = config

  const organisationIndex = data.organisations.findIndex(t => t._id === _id)
  const $organisation = data.organisations.splice(organisationIndex, 1)
  
  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $organisation._id,
    "model": "Organisation",
    "action": "primary:delete:organisation",
    "data": $organisation
  }

  data.logs.unshift($log)

  await delay(1000)

  return [200, { msg: `Organisation '${_id}' has been successfully deleted`, action: "primary:delete:organisation" }]
})