import { populates, data as db } from './index';

export const data = {
  practices: [
    {
      "_id" : "64f707a1e5d622569fa260c8",
      "primary": {
        "common": {
          "stylesheet" : "64f6fac65b96fa2292f725c7",
          "assets" : {
            "image" : "64f70761e5c620569fa26056"
          },
          "pass": 20,
          "duration": 20,
          "claims": 20,
        },
        "items" : [
          {
            "language" : "5e2313693993211e8128b315",
            "entry" : {
              "title" : "Food poisoning",
              "description" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            },
            "_id" : "64ff0a8e3b6488ada5226c8a"
          },
          {
            "language" : "5e2302d95187570c9c399dd3",
            "entry" : {
              "title" : "Food poisoning",
              "description" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            },
            "_id" : "64ff0a8e3b6488ada5226c8b"
          }
        ],        
      },
      "createdBy" : "5b1fa75598a9cb165a5fa758",
      "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
      "elements" : [
        "64f86aa543a82f13a4847707",
        "64f87dcefe7f425260ddb872",
        "64f86aa553a82f13a4147707",
        "64f86ad553182f13a4847707",
        "64f86ae553a82f13a5847207",
        "64f26aa5c3a82f13a4847707",        
      ],
      "createdAt" : "2023-05-05T10:49:05.577+0000",
      "updatedAt" : "2023-08-11T12:39:42.450+0000",
      "publish" : {
        "status" : "published"
      }
    },
    {
      "_id" : "64f707a1e5d6205a9aa260c8",
      "primary": {
        "common": {
          "stylesheet" : "64f6fac65b96fa2292f725c7",
          "assets" : {
            "image" : "64f70761e5c620569fa26056"
          },
          "pass": 17,
          "duration": 20,
          "claims": 20,
        },
        "items" : [
          {
            "language" : "5e2313693993211e8128b315",
            "entry" : {
              "title" : "Treatments to improve the lasting of food products",
              "description" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            },
            "_id" : "64ff0a8e3b6488ada5226c8a"
          },
          {
            "language" : "5e2302d95187570c9c399dd3",
            "entry" : {
              "title" : "Treatments to improve the lasting of food products",
              "description" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            },
            "_id" : "64ff0a8e3b6488ada5226c8b"
          }
        ],        
      },      
      "createdBy" : "5b1fa75598a9cb165a5fa758",
      "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
      "elements" : [
        "64f86aa553a82f13a4847707",
        "64f86ad553a82f13a4847707",
        "64f86ae553a82f13a4847207",
        "64f26aa553a82f13a4847707",
      ],
      "createdAt" : "2023-02-03T10:49:05.577+0000",
      "updatedAt" : "2023-05-04T12:39:42.450+0000",
      "publish" : {
        "status" : "draft"
      }
    }    
  ]
}

export const populate = ({ iter, }) => {
  let $item = JSON.parse(JSON.stringify({ ...iter }))

  let { languages, users, stylesheets } = db;
  languages = languages.map(l=>populates.language({ iter: l }))
  // users = users.map(l=>populates.user({ iter: l }))
  stylesheets = stylesheets.map(l=>populates.stylesheet({ iter: l }))

  $item.primary.items = $item.primary.items.map(t=>{ 
    return {
      ...t, 
      language: languages.find(j=> { return j._id === t.language  }) 
    }
  })

  $item.primary.common.stylesheet = stylesheets.find(u=>u._id === $item.primary.common.stylesheet)
  $item.lastUpdatedBy = users.find(u=>u._id === $item.lastUpdatedBy)
  $item.createdBy = users.find(u=>u._id === $item.createdBy)

  return $item
}