export const data = {
  organisations: [
    {
        "_id": "5b1fa55198a9cb225a5fa758",
        "name": "Organisation Lorem",
    },
    {
        "_id": "5b1fa52198a9cb225abfa759",
        "name": "Organisation Ipsum",
    },    
  ]
}

export const populate = ({ iter, }) => {
  let $item = JSON.parse(JSON.stringify({ ...iter }))
  return $item
}