import mock from './mock'
import { paginateArray, sortCompare, move, delay } from './utils'

import { data, populates } from './data'


mock.onGet('/mocks/courses/index').reply(async config => {
  const { q = '', perPage = 10, page = 1, } = config

  const $courses = data.courses.map(e=> populates.course({ iter: e, }) )
  const $environments = data.environments.map(e=> populates.environment({ iter: e, }) )

  const queryLowered = q.toLowerCase()
  const filteredData = data.courses
    .map(course=>{
      if (course.publish?.status === "published"){
        const $environment = $environments.find(e=>e.courses && e.courses.find(c=>c._id === course._id))
        if ($environment) course.index = $environment.courses.findIndex(c=>c._id === course._id) + 1
      }
      return course
    })
    .map(course=>populates.course({ iter: course }))
    .filter(
      course =>
        course?._id?.toLowerCase().includes(queryLowered) ||
        course?.name?.toLowerCase().includes(queryLowered) ||
        course?.createdAt?.toLowerCase().includes(queryLowered) ||
        course?.code?.toLowerCase().includes(queryLowered)
    )
    .sort(sortCompare('_id'))
    .reverse()

  await delay(1000)

  return [
    200, {
      courses: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

mock.onGet('/mocks/courses/new').reply(async config => {
  await delay(1000)

  const $practices = data.practices.map(e=> populates.practice({ iter: e, }) )
  const $languages = data.languages.map(e=> populates.language({ iter: e, }) )
  const $stylesheets = data.stylesheets.map(e=> populates.stylesheet({ iter: e, }) )
                       .filter(a=>a.types.includes("course") )
  const $assets = data.assets.map(e=> populates.asset({ iter: e, }) )
                  .filter(a=>a.category === "image:background:course" )

  return [200, { middlewares: { 
    languages: $languages,
    stylesheets: $stylesheets,
    assets: $assets,
    practices: $practices,
  } }]
})

mock.onGet('/mocks/courses/edit').reply(async config => {
  const { id } = config
  const course = data.courses.find(i => i._id === id)
  if (!course) return [404, { msg: "Course not found" }]

  const $course = populates.course({ iter: course })

  const $practices = data.practices.map(e=> populates.practice({ iter: e, }) )
  const $languages = data.languages.map(e=> populates.language({ iter: e, }) )
  const $stylesheets = data.stylesheets.map(e=> populates.stylesheet({ iter: e, }) )
                       .filter(a=>a.types.includes("course") )
  const $assets = data.assets.map(e=> populates.asset({ iter: e, }) )
                  .filter(a=>a.category === "image:background:course" )

  await delay(1000)

  return [200, { course: $course, middlewares: { 
    languages: $languages,
    stylesheets: $stylesheets,
    assets: $assets,
    practices: $practices,
  } }]
})

mock.onPost('/mocks/courses/create').reply(async config => {
  const course = JSON.parse(config.data)

  const $course = {
    _id: "5b1fa75598a9cb165bbfa758",
    createdAt : "2023-10-12T10:58:29.022+0000",
    updatedAt : "2023-10-12T10:57:09.839+0000",    
    createdBy: "5b1fa75598a9cb165a5fa758",
    lastUpdatedBy: "5b1fa75598a9cb165a5fa758",

    primary: {
      items: Object.entries(course.items).map(e=>{
        return { language: e[0], entry: e[1] }
      }),      
      common: {
        assets: {
          image: course.image
        },        
        stylesheet: course.stylesheet,
        practice: course.practice,
      },
    },
    publish: {
      status: course.status,
    }    
  }

  data.courses.unshift($course)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": "5b1fa75598a9cb165bbfa758",
    "model": "Course",
    "action": "primary:create:course",
    "data": $course
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Course '${$course._id}' has been successfully created`, action: "primary:create:course", course: $course, }]
})

mock.onPost('/mocks/courses/update').reply(async config => {
  const course = JSON.parse(config.data)

  let $course = data.courses.find(i => i._id === course?._id)
  if (!$course) return [404, { msg: "Course not found" }]

  data.courses = data.courses.filter(u=>u._id !== course?._id)

  $course = { ...$course, 
    primary: {
      ...$course.primary,
      common: {
        ...$course.common,
        assets: {
          image: course.image
        },
        stylesheet: course.stylesheet,
        practice: course.practice,
      },
      items: Object.entries(course.items).map(e=>{
        return { language: e[0], entry: e[1] }
      })
    },
    publish: {
      status: course.status,
    }
  }

  data.courses.unshift($course)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $course._id,
    "model": "Course",
    "action": "primary:update:course",
    "data": $course
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Course '${$course._id}' has been successfully updated`, action: "primary:update:course", course: $course, }]
})

mock.onGet('/mocks/courses/logs').reply(config => {
  const { q = '', perPage = 10, page = 1, target = null } = config

  const queryLowered = q.toLowerCase()
  const filteredData = data.logs
    .filter(l=>l.target === target )
    .filter(l=>l?.action?.toLowerCase().includes(queryLowered))
    .sort(sortCompare('_id'))
    .reverse()

  return [
    200, {
      logs: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

mock.onGet('/mocks/courses/usage').reply(config => {
  const { q = '', perPage = 10, page = 1, target = null } = config

  const users = data.users.filter(u=>u.course === target)

  return [
    200, {
      usage: {
        users: users
      },
      total: 0
    }
  ]
})

mock.onDelete('/mocks/courses/delete').reply(async config => {
  const { _id } = config

  const courseIndex = data.courses.findIndex(t => t._id === _id)
  const $course = data.courses.splice(courseIndex, 1)
  
  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $course._id,
    "model": "Course",
    "action": "primary:delete:course",
    "data": $course
  }

  data.logs.unshift($log)

  await delay(1000)

  return [200, { msg: `Course '${_id}' has been successfully deleted`, action: "primary:delete:course" }]
})

mock.onPost('/mocks/courses/remove/lesson').reply(async config => {
  const course = JSON.parse(config.data)

  let $course = data.courses.find(i => i._id === course?.courseId)
  if (!$course) return [404, { msg: "Course not found" }]

  data.courses = data.courses.filter(u=>u._id !== course?.courseId)

  $course = { 
    ...$course, 
    lessons: $course.lessons.filter(l=>l !== course.lessonId)
  }

  data.courses.unshift($course)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $course._id,
    "model": "Course",
    "action": "primary:update:course:remove:lesson",
    "data": $course
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Lesson '${course.lessonId}' has been successfully removed from course ${course?.courseId}.`, action: "primary:update:course:remove:lesson", course: $course, }]
})

mock.onPost('/mocks/courses/move/lesson').reply(async config => {
  const course = JSON.parse(config.data)

  let $course = data.courses.find(i => i._id === course?.courseId)
  if (!$course) return [404, { msg: "Course not found" }]

  data.courses = data.courses.filter(u=>u._id !== course?.courseId)

  $course = {  ...$course, }


  const dir = course.dir

  const _from = $course.lessons.findIndex(i=> i === course.lessonId)
  const d = dir === "up" ? -1 : dir === "down" ? 1 : 0
  const _to = _from + d

  move($course.lessons)(_from, _to);


  data.courses.unshift($course)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $course._id,
    "model": "Course",
    "action": "primary:update:course:move:lesson",
    "data": $course
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Lesson '${course.lessonId}' index has been successfully moved in course ${course?.courseId}.`, action: "primary:update:course:move:lesson", course: $course, }]
})
