import { populates, data as db } from './index';

export const data = {
  languages: [
    {
      "_id" : "5e2313693993211e8128b315",
      "name" : "Finnish",
      "code" : "fi",
      "createdAt" : "2018-06-12T10:58:29.022+0000",
      "updatedAt" : "2023-09-06T10:57:09.839+0000",
      "assets": {
        "image": "5e231363399321148128b316"
      }
    },
    {
      "_id" : "5e2302d95187570c9c399dd3",
      "name" : "English",
      "code" : "en",
      "createdAt" : "2018-06-12T10:58:29.022+0000",
      "updatedAt" : "2023-09-06T10:57:09.839+0000",
      "assets": {
        "image": "5e2302d95487570c93392dd9"
      }      
    }  
  ]
}

export const populate = ({ iter, }) => {
  let $item = JSON.parse(JSON.stringify({ ...iter }))

  let { assets } = db;

  // console.log("assets", assets, $item, $item?.assets?.image )

  $item.assets.image = assets.find(u=>u._id === $item?.assets?.image)

  return $item
}