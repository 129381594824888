import { populates, data as db } from './index';

export const data = {
  users: [
    {
      "_id" : "5b1fa75598a9cb165a5fa758",
      "email" : "admin@osaa.fi",
      "password": 'admin',
      "createdAt" : "2018-06-12T10:58:29.022+0000",
      "updatedAt" : "2023-09-06T10:57:09.839+0000",
      "profile" : {
        "name" : "Superadmin",
        "phone_number" : "+358449566793"
      },
      "role" : "5b3cc254e02cf03b11cc181b",
      "lastActiveAt" : "2023-09-06T10:57:09.838+0000",
      "organisation": "5b1fa52198a9cb225abfa759",
      ability: [
        {
          action: 'manage',
          subject: 'all'
        }
      ], 
      active: {
        environment: null,
        languages: {
          primary: null,
          secondary: null,
        },
      }
    },
    {
      "_id" : "5b1fa7559aa9cb165a5fa758",
      "email" : "user@osaa.fi",
      "password": 'user',
      "createdAt" : "2018-06-12T10:58:29.022+0000",
      "updatedAt" : "2023-09-06T10:57:09.839+0000",
      "profile" : {
        "name" : "User",
        "phone_number" : "+358449566793"
      },
      "role" : "5b3cc265e02cf03b11cc181c",
      "lastActiveAt" : "2023-09-06T10:57:09.838+0000",
      "createdBy" : "5b1fa75598a9cb165a5fa758",   
      "lastUpdatedBy": "5b1fa75598a9cb165a5fa758",
      ability: [
        {
          action: 'read',
          subject: 'ACL'
        },
        {
          action: 'read',
          subject: 'Auth'
        }
      ],
      active: {
        environment: null,
        languages: {
          primary: null,
          secondary: null,
        },
      }      
    },
    {
      "_id" : "5b1fa7559aa9cb265a5fa758",
      "email" : "translator@osaa.fi",
      "password": 'translator',
      "createdAt" : "2018-06-12T10:58:29.022+0000",
      "updatedAt" : "2023-09-06T10:57:09.839+0000",
      "profile" : {
        "name" : "Translator",
        "phone_number" : "+358449566793"
      },
      "role" : "5b3cc265e02cf03b11cc181d",
      "lastActiveAt" : "2023-09-06T10:57:09.838+0000",
      "createdBy" : "5b1fa75598a9cb165a5fa758",
      "lastUpdatedBy": "5b1fa75598a9cb165a5fa758",
      "privileges": [
        { typ: "environment", target: "64f707a1e50620169fa260c8", scopes: [
          { language: "5e2313693993211e8128b315" },
          { language: "5e2302d95187570c9c399dd3" }
        ] },
        { typ: "environment", target: "64f5cef0c062fcc020481df8", scopes: [
          { language: "5e2302d95187570c9c399dd3" }
        ] },        
      ],
      ability: [
        {
          action: 'read',
          subject: 'ACL'
        },
        {
          action: 'read',
          subject: 'Auth'
        }
      ],
      active: {
        environment: null,
        languages: {
          primary: null,
          secondary: null,
        },
      }      
    }

  ]
}

export const populate = ({ iter,  }) => {
  let $item = JSON.parse(JSON.stringify({ ...iter }))

  let { roles, users, environments, languages, organisations } = db;
  roles = roles.map(l=>populates.role({ iter: l }))
  environments = environments.map(l=>populates.environment({ iter: l }))
  languages = languages.map(l=>populates.language({ iter: l }))
  organisations = organisations.map(l=>populates.organisation({ iter: l }))

  $item.createdBy = users.find(u=>u._id === $item.createdBy)
  $item.lastUpdatedBy = users.find(u=>u._id === $item.lastUpdatedBy)
  $item.role = roles.find(u=>u._id === $item.role)
  $item.organisation = organisations.find(u=>u._id === $item.organisation)


  if ($item?.active?.environment) $item.active.environment = environments.find(u=>u._id === $item.active.environment)
  if ($item?.active?.languages?.primary) $item.active.languages.primary = languages.find(u=>u._id === $item.active.languages.primary)
  if ($item?.active?.languages?.secondary) $item.active.languages.secondary = languages.find(u=>u._id === $item.active.languages.secondary)

  
  if ($item.privileges){
    $item.privileges = $item.privileges.map(p=>({
      ...p, 
      target: environments.find(e=>e._id === p.target),
      scopes: p.scopes.map(s=>({ ...s, language: languages.find(k=>k._id === s.language ) }))
    })) 
  }

  return $item
}