import mock from './mock'

import './courses'
import './claims'
import './practices'
import './users'
import './lessons'
import './organisations'
import './languages'
import './environments'
import './roles'
import './jwt'

import './back/tables/datatables'
import './back/autoComplete/autoComplete'
import './back/navbar/navbarSearch'
import './back/apps/email'
import './back/apps/chat'
import './back/apps/todo'
import './back/apps/calendar'
import './back/apps/userList'
import './back/apps/invoice'
import './back/apps/eCommerce'
import './back/pages/account-settings'
import './back/pages/profile'
import './back/pages/faq'
import './back/pages/knowledge-base'
import './back/pages/pricing-data'
import './back/pages/blog-data'
import './back/cards/card-analytics'
import './back/cards/card-statistics'


mock.onAny().passThrough()
