import { data as users, populate as populate_user } from './users'
import { data as environments, populate as populate_environment } from './environments'
import { data as languages, populate as populate_language } from './languages'
import { data as roles, populate as populate_role  } from './roles'
import { data as practices, populate as populate_practice  } from './practices'
import { data as stylesheets, populate as populate_stylesheet  } from './stylesheets'
import { data as organisations, populate as populate_organisation  } from './organisations'
import { data as logs, populate as populate_log  } from './logs'
import { data as assets, populate as populate_asset  } from './assets'
import { data as courses, populate as populate_course  } from './courses'
import { data as lessons, populate as populate_lesson  } from './lessons'
import { data as claims, populate as populate_claim  } from './claims'

export const data = {
	...users,
	...environments,
	...languages,
	...roles,
	...practices,
	...stylesheets,
	...organisations,
	...logs,
	...assets,
	...courses,
	...lessons,
	...claims
}

export const populates = {
	environment: populate_environment,
	user: populate_user,	
	language: populate_language,
	role: populate_role,
	practice: populate_practice,
	stylesheet: populate_stylesheet,
	organisation: populate_organisation,
	log: populate_log,
	asset: populate_asset,
	course: populate_course,
	lesson: populate_lesson,
	claim: populate_claim,
}