import mock from './mock'
import { paginateArray, sortCompare, delay } from './utils'

import { data, populates } from './data'


mock.onGet('/mocks/languages/index').reply(async config => {
  const { q = '', perPage = 10, page = 1, } = config

  const queryLowered = q.toLowerCase()
  const filteredData = data.languages
    .map(language=>populates.language({ iter: language }))
    .filter(
      language =>
        language?._id?.toLowerCase().includes(queryLowered) ||
        language?.name?.toLowerCase().includes(queryLowered) ||
        language?.createdAt?.toLowerCase().includes(queryLowered) ||
        language?.code?.toLowerCase().includes(queryLowered)
    )
    .sort(sortCompare('_id'))
    .reverse()

  await delay(1000)

  return [
    200, {
      languages: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

mock.onGet('/mocks/languages/new').reply(async config => {
  const $assets = data.assets.map(e=> populates.asset({ iter: e, }) )
                    .filter(a=>a.category === "image:logo:language")

  await delay(1000)

  return [200, { middlewares: { 
    assets: $assets
  } }]
})

mock.onGet('/mocks/languages/edit').reply(async config => {
  const { id } = config
  const language = data.languages.find(i => i._id === id)
  if (!language) return [404, { msg: "Language not found" }]

  const $language = populates.language({ iter: language })

  const $assets = data.assets.map(e=> populates.asset({ iter: e, }) )
                    .filter(a=>a.category === "image:logo:language")

  await delay(1000)

  return [200, { language: $language, middlewares: { 
    assets: $assets
  } }]
})

mock.onPost('/mocks/languages/create').reply(async config => {
  const language = JSON.parse(config.data)

  const $language = {
    _id: "5b1fa75598a9cb165bbfa758",
    name: language.name,
    code: language.code,
    assets: { image: language.image, },
    createdAt : "2023-10-12T10:58:29.022+0000",
    updatedAt : "2023-10-12T10:57:09.839+0000",    
  }

  data.languages.unshift($language)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": "5b1fa75598a9cb165bbfa758",
    "model": "Language",
    "action": "primary:create:language",
    "data": $language
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Language '${$language._id}' has been successfully created`, action: "primary:create:language", language: $language, }]
})

mock.onPost('/mocks/languages/update').reply(async config => {
  const language = JSON.parse(config.data)

  let $language = data.languages.find(i => i._id === language?._id)
  if (!$language) return [404, { msg: "Language not found" }]

  data.languages = data.languages.filter(u=>u._id !== language?._id)

  $language = { ...$language, 
    name: language.name,
    code: language.code,
    assets: { image: language.image, },
  }

  data.languages.unshift($language)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $language._id,
    "model": "Language",
    "action": "primary:update:language",
    "data": $language
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Language '${$language._id}' has been successfully updated`, action: "primary:update:language", language: $language, }]
})

mock.onGet('/mocks/languages/logs').reply(config => {
  const { q = '', perPage = 10, page = 1, target = null } = config

  const queryLowered = q.toLowerCase()
  const filteredData = data.logs
    .filter(l=>l.target === target )
    .filter(
      l =>
        l?.action?.toLowerCase().includes(queryLowered)
    )    
    .sort(sortCompare('_id'))
    .reverse()

  return [
    200, {
      logs: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

mock.onGet('/mocks/languages/usage').reply(config => {
  const { q = '', perPage = 10, page = 1, target = null } = config

  return [
    200, {
      usage: {
        items: []
      },
      total: 0
    }
  ]
})

mock.onDelete('/mocks/languages/delete').reply(async config => {
  const { _id } = config

  const languageIndex = data.languages.findIndex(t => t._id === _id)
  const $language = data.languages.splice(languageIndex, 1)
  
  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $language._id,
    "model": "Language",
    "action": "primary:delete:language",
    "data": $language
  }

  data.logs.unshift($log)

  await delay(1000)

  return [200, { msg: `Language '${_id}' has been successfully deleted`, action: "primary:delete:language" }]
})