// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'

import loading from './loading'

import chat from '@src/views/back/apps/chat/store/reducer'
import todo from '@src/views/back/apps/todo/store/reducer'
import _users from '@src/views/back/apps/user/store/reducer'
import email from '@src/views/back/apps/email/store/reducer'
import invoice from '@src/views/back/apps/invoice/store/reducer'
import calendar from '@src/views/back/apps/calendar/store/reducer'
import ecommerce from '@src/views/back/apps/ecommerce/store/reducer'
import dataTables from '@src/views/back/tables/data-tables/store/reducer'

import courses from '@src/views/courses/store/reducer'
import practices from '@src/views/practices/store/reducer'
import lessons from '@src/views/lessons/store/reducer'
import users from '@src/views/users/store/reducer'
import organisations from '@src/views/organisations/store/reducer'
import languages from '@src/views/languages/store/reducer'
import environments from '@src/views/environments/store/reducer'
import roles from '@src/views/roles/store/reducer'
import claims from '@src/views/claims/store/reducer'
import stylesheets from '@src/views/stylesheets/store/reducer'
import assets from '@src/views/assets/store/reducer'
import tips from '@src/views/tips/store/reducer'
import notices from '@src/views/notices/store/reducer'
import coupons from '@src/views/coupons/store/reducer'
import couponGroups from '@src/views/coupon-groups/store/reducer'
import services from '@src/views/services/store/reducer'
import account from '@src/views/account/store/reducer'

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  _users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,

  loading,

  lessons,
  courses,
  practices,
  users,
  organisations,
  languages,
  environments,
  roles,
  claims,
  stylesheets,
  assets,
  tips,
  notices,
  coupons,
  couponGroups,
  services,
  account,
})

export default rootReducer
