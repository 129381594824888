import { populates, data as db } from './index';

export const data = {
  courses: [
    {
      "_id" : "64f707a1e5c620569fa260c8",
      "primary": {
        "common": {
          "category": "article",
          "practice": "64f707a1e5d622569fa260c8",
          "environment" : "64f5cef0c062fcc020481df8",
          "stylesheet" : "64f5bd48134d5e63d92d39b1",
          "assets" : {
            "image" : "5e2301d91487575c93392dd9"
          }
        },
        "items" : [
          {
            "language" : "5e2313693993211e8128b315",
            "entry" : {
              "title" : "Acquirement, transportation and receival of food products",
              "description" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            },
            "_id" : "64ff0a8e3b6488ada5226c8a"
          },
          {
            "language" : "5e2302d95187570c9c399dd3",
            "entry" : {
              "title" : "Acquirement, transportation and receival of food products",
              "description" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            },
            "_id" : "64ff0a8e3b6488ada5226c8b"
          }
        ]        
      },
      "createdBy" : "5b1fa75598a9cb165a5fa758",
      "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
      "lessons" : [
        "64f707a1eacb221c9fa260c8",
        "64f707a1e5d6205a9fa260c8",
        "64f707a1eacb221c9fa260c8",
        "64f707a1e5d6205a9fa260c8",
        "64f707a1eacb221c9fa260c8",
        "64f707a1e5d6205a9fa260c8",
        "64f707a1eacb221c9fa260c8",
        "64f707a1e5d6205a9fa260c8",
        "64f707a1eacb221c9fa260c8",                        
      ],
      "createdAt" : "2023-09-05T10:49:05.577+0000",
      "updatedAt" : "2023-09-11T12:39:42.450+0000",
      "publish" : {
        "status" : "published"
      }
    },
    {
      "_id" : "64f707a1e5d620569fa260c8",
      "primary": {
        "common": {
          "category": "video",
          "practice": "64f707a1e5d6205a9aa260c8",
          "environment" : "64f707a1e50620169fa260c8",
          "stylesheet" : "64f5bd48134d5e63d92d39b1",
          "assets" : {
            "image" : "5e2301d91487575c93392dd9"
          },
        },
        "items" : [
          {
            "language" : "5e2313693993211e8128b315",
            "entry" : {
              "title" : "Storage, cold storage and procedures to improve lastingness",
              "description" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            },
            "_id" : "64ff0a8e3b6488ada5226c8a"
          },
          {
            "language" : "5e2302d95187570c9c399dd3",
            "entry" : {
              "title" : "Storage, cold storage and procedures to improve lastingness",
              "description" : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            },
            "_id" : "64ff0a8e3b6488ada5226c8b"
          }
        ],        
      },      
      "createdBy" : "5b1fa75598a9cb165a5fa758",
      "lastUpdatedBy" : "5b1fa75598a9cb165a5fa758",
      "lessons" : [

      ],
      "createdAt" : "2023-09-03T10:49:05.577+0000",
      "updatedAt" : "2023-09-04T12:39:42.450+0000",
      "publish" : {
        "status" : "draft"
      }
    }    
  ]
}

export const populate = ({ iter, }) => {
  let $item = JSON.parse(JSON.stringify({ ...iter }))

  let { languages, users, stylesheets, environments, assets, practices, lessons } = db;
  languages = languages.map(l=>populates.language({ iter: l }))
  // users = users.map(l=>populates.user({ iter: l }))
  stylesheets = stylesheets.map(l=>populates.stylesheet({ iter: l }))
  practices = practices.map(l=>populates.practice({ iter: l }))
  lessons = lessons.map(l=>populates.lesson({ iter: l }))


  $item.primary.items = $item.primary.items.map(t=>{ 
    return {
      ...t, 
      language: languages.find(j=> { return j._id === t.language  }) 
    }
  })

  if ($item.lessons){
    $item.lessons = $item.lessons.map(l=>({
      ...lessons.find(u=>u._id === l)
    })) 
  }

  $item.primary.common.practice = practices.find(u=>u._id === $item.primary.common.practice)
  $item.primary.common.assets.image = assets.find(u=>u._id === $item.primary.common.assets.image)
  $item.primary.common.stylesheet = stylesheets.find(u=>u._id === $item.primary.common.stylesheet)
  $item.primary.common.environment = environments.find(u=>u._id === $item.primary.common.environment)
  $item.lastUpdatedBy = users.find(u=>u._id === $item.lastUpdatedBy)
  $item.createdBy = users.find(u=>u._id === $item.createdBy)

  return $item
}