import mock from './mock'
import { paginateArray, sortCompare, delay } from './utils'

import { data, populates } from './data'


mock.onGet('/mocks/practices/index').reply(async config => {
  const { q = '', perPage = 10, page = 1, } = config

  const $practices = data.practices.map(e=> populates.practice({ iter: e, }) )
  const $environments = data.environments.map(e=> populates.environment({ iter: e, }) )

  const queryLowered = q.toLowerCase()
  const filteredData = data.practices
    .map(practice=>{
      if (practice.publish?.status === "published"){
        const $environment = $environments.find(e=>e.practices && e.practices.find(c=>c?.target?._id === practice._id))
        if ($environment) practice.index = $environment.practices.findIndex(c=>c?.target?._id === practice._id) + 1
      }
      return practice
    })  
    .map(practice=>populates.practice({ iter: practice }))
    .filter(
      practice =>
        practice?._id?.toLowerCase().includes(queryLowered) ||
        practice?.name?.toLowerCase().includes(queryLowered) ||
        practice?.createdAt?.toLowerCase().includes(queryLowered)
    )
    .sort(sortCompare('_id'))
    .reverse()

  await delay(1000)

  return [
    200, {
      practices: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

mock.onGet('/mocks/practices/new').reply(async config => {
  await delay(1000)

  const $practices = data.practices.map(e=> populates.practice({ iter: e, }) )
  const $languages = data.languages.map(e=> populates.language({ iter: e, }) )
  const $stylesheets = data.stylesheets.map(e=> populates.stylesheet({ iter: e, }) )
                       .filter(a=>a.types.includes("practice") )
  const $assets = data.assets.map(e=> populates.asset({ iter: e, }) )
                  .filter(a=>a.category === "image:background:practice" )

  return [200, { middlewares: { 
    languages: $languages,
    stylesheets: $stylesheets,
    assets: $assets,
    practices: $practices,
  } }]
})

mock.onGet('/mocks/practices/edit').reply(async config => {
  const { id } = config
  const practice = data.practices.find(i => i._id === id)
  if (!practice) return [404, { msg: "Practice not found" }]

  const $practice = populates.practice({ iter: practice })

  const $claims = data.claims.map(e=> populates.claim({ iter: e, }) )
                  .filter(a=>a.primary?.common?.practice?._id === $practice._id )
  const $languages = data.languages.map(e=> populates.language({ iter: e, }) )
  const $stylesheets = data.stylesheets.map(e=> populates.stylesheet({ iter: e, }) )
                       .filter(a=>a.types.includes("practice") )
  const $assets = data.assets.map(e=> populates.asset({ iter: e, }) )
                  .filter(a=>a.category === "image:background:practice" )

  // console.log("$claims", $claims)

  await delay(1000)

  return [200, { practice: $practice, middlewares: { 
    languages: $languages,
    stylesheets: $stylesheets,
    assets: $assets,
    claims: $claims,
  } }]
})

mock.onPost('/mocks/practices/create').reply(async config => {
  const practice = JSON.parse(config.data)

  const $practice = {
    _id: "5b1fa75598a9cb165bbfa758",
    createdAt : "2023-10-12T10:58:29.022+0000",
    updatedAt : "2023-10-12T10:57:09.839+0000",    
    createdBy: "5b1fa75598a9cb165a5fa758",
    lastUpdatedBy: "5b1fa75598a9cb165a5fa758",

    primary: {
      items: Object.entries(practice.items).map(e=>{
        return { language: e[0], entry: e[1] }
      }),      
      common: {
        pass: practice.pass,
        duration: practice.duration,
        claims: practice.claims,        
        assets: {
          image: practice.image
        },        
        stylesheet: practice.stylesheet,
      },
    },
    publish: {
      status: practice.status,
    }    
  }

  // console.log("$practice", $practice)

  data.practices.unshift($practice)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": "5b1fa75598a9cb165bbfa758",
    "model": "Practice",
    "action": "primary:create:practice",
    "data": $practice
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Practice '${$practice._id}' has been successfully created`, action: "primary:create:practice", practice: $practice, }]
})

mock.onPost('/mocks/practices/update').reply(async config => {
  const practice = JSON.parse(config.data)

  let $practice = data.practices.find(i => i._id === practice?._id)
  if (!$practice) return [404, { msg: "Practice not found" }]

  data.practices = data.practices.filter(u=>u._id !== practice?._id)

  $practice = { ...$practice, 
    primary: {
      ...$practice.primary,
      common: {
        ...$practice.common,
        pass: practice.pass,
        duration: practice.duration,
        claims: practice.claims,
        assets: {
          image: practice.image
        },
        stylesheet: practice.stylesheet,
      },
      items: Object.entries(practice.items).map(e=>{
        return { language: e[0], entry: e[1] }
      })
    },
    publish: {
      status: practice.status,
    }
  }

  data.practices.unshift($practice)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $practice._id,
    "model": "Practice",
    "action": "primary:update:practice",
    "data": $practice
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Practice '${$practice._id}' has been successfully updated`, action: "primary:update:practice", practice: $practice, }]
})

mock.onGet('/mocks/practices/logs').reply(config => {
  const { q = '', perPage = 10, page = 1, target = null } = config

  const queryLowered = q.toLowerCase()
  const filteredData = data.logs
    .filter(l=>l.target === target )
    .filter(l=>l?.action?.toLowerCase().includes(queryLowered))
    .sort(sortCompare('_id'))
    .reverse()

  return [
    200, {
      logs: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

mock.onGet('/mocks/practices/usage').reply(config => {
  const { q = '', perPage = 10, page = 1, target = null } = config

  const users = data.users.filter(u=>u.practice === target)

  return [
    200, {
      usage: {
        users: users
      },
      total: 0
    }
  ]
})

mock.onDelete('/mocks/practices/delete').reply(async config => {
  const { _id } = config

  const practiceIndex = data.practices.findIndex(t => t._id === _id)
  const $practice = data.practices.splice(practiceIndex, 1)
  
  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $practice._id,
    "model": "Practice",
    "action": "primary:delete:practice",
    "data": $practice
  }

  data.logs.unshift($log)

  await delay(1000)

  return [200, { msg: `Practice '${_id}' has been successfully deleted`, action: "primary:delete:practice" }]
})