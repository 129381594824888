import mock from './mock'
import { paginateArray, sortCompare, move, delay } from './utils'

import { data, populates } from './data'


mock.onGet('/mocks/environments/index').reply(async config => {
  const { q = '', perPage = 10, page = 1, } = config

  const $courses = data.courses.map(e=> populates.course({ iter: e, }) )

  const queryLowered = q.toLowerCase()
  const filteredData = data.environments
    .map(environment=>populates.environment({ iter: environment }))
    .map(environment=>({ ...environment, courses: $courses.filter(c=>c.primary.common.environment._id === environment._id ) }))
    .filter(
      environment =>
        environment?._id?.toLowerCase().includes(queryLowered) ||
        environment?.name?.toLowerCase().includes(queryLowered) ||
        environment?.createdAt?.toLowerCase().includes(queryLowered) ||
        environment?.code?.toLowerCase().includes(queryLowered)
    )
    .sort(sortCompare('_id'))
    .reverse()

  await delay(1000)

  return [
    200, {
      environments: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

mock.onGet('/mocks/environments/new').reply(async config => {
  await delay(1000)

  const $languages = data.languages.map(e=> populates.language({ iter: e, }) )
  const $stylesheets = data.stylesheets.map(e=> populates.stylesheet({ iter: e, }) )
                       .filter(a=>a.types.includes("environment") )
  const $assets = data.assets.map(e=> populates.asset({ iter: e, }) )
                  .filter(a=>a.category === "image:background:environment" )

  return [200, { middlewares: { 
    languages: $languages,
    stylesheets: $stylesheets,
    assets: $assets    
  } }]
})

mock.onGet('/mocks/environments/edit').reply(async config => {
  const { id } = config
  const environment = data.environments.find(i => i._id === id)
  if (!environment) return [404, { msg: "Environment not found" }]

  const $environment = populates.environment({ iter: environment })

  const $languages = data.languages.map(e=> populates.language({ iter: e, }) )
  const $stylesheets = data.stylesheets.map(e=> populates.stylesheet({ iter: e, }) )
                      .filter(a=>a.types.includes("environment") )
  const $assets = data.assets.map(e=> populates.asset({ iter: e, }) )
                  .filter(a=>a.category === "image:background:environment" )

  await delay(1000)

  return [200, { environment: $environment, middlewares: { 
    languages: $languages,
    stylesheets: $stylesheets,
    assets: $assets
  } }]
})

mock.onPost('/mocks/environments/create').reply(async config => {
  const environment = JSON.parse(config.data)

  const $environment = {
    _id: "5b1fa75598a9cb165bbfa758",
    createdAt : "2023-10-12T10:58:29.022+0000",
    updatedAt : "2023-10-12T10:57:09.839+0000",    

    primary: {
      items: Object.entries(environment.items).map(e=>{
        return { language: e[0], entry: e[1] }
      }),      
      common: {
        assets: {
          image: environment.image
        },        
        stylesheet: environment.stylesheet
      },
    },
    languages: environment.languages.map(l=>{ 
      return { target: l._id, publish: { status: l.status } }
    }),
    practices: [],
  }

  data.environments.unshift($environment)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": "5b1fa75598a9cb165bbfa758",
    "model": "Environment",
    "action": "primary:create:environment",
    "data": $environment
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Environment '${$environment._id}' has been successfully created`, action: "primary:create:environment", environment: $environment, }]
})

mock.onPost('/mocks/environments/update').reply(async config => {
  const environment = JSON.parse(config.data)

  let $environment = data.environments.find(i => i._id === environment?._id)
  if (!$environment) return [404, { msg: "Environment not found" }]

  data.environments = data.environments.filter(u=>u._id !== environment?._id)

  $environment = { ...$environment, 
    primary: {
      ...$environment.primary,
      common: {
        ...$environment.primary.common,
        assets: {
          image: environment.image
        },
        stylesheet: environment.stylesheet
      },
      items: Object.entries(environment.items).map(e=>{
        return { language: e[0], entry: e[1] }
      })
    },
    languages: environment.languages.map(l=>{ 
      return { target: l._id, publish: { status: l.status } }
    })
  }

  data.environments.unshift($environment)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $environment._id,
    "model": "Environment",
    "action": "primary:update:environment",
    "data": $environment
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Environment '${$environment._id}' has been successfully updated`, action: "primary:update:environment", environment: $environment, }]
})

mock.onPost('/mocks/environments/update/final').reply(async config => {
  const environment = JSON.parse(config.data)

  let $environment = data.environments.find(i => i._id === environment?._id)
  if (!$environment) return [404, { msg: "Environment not found" }]

  data.environments = data.environments.filter(u=>u._id !== environment?._id)

  const $itemEntries = Object.entries(environment.items)

  $environment = { ...$environment, 
    primary: {
      ...$environment.primary,
      common: {
        ...$environment.primary.common,
        final: {
          pass: environment.pass,
          duration: environment.duration,
          claims: environment.claims,             
        }
      },
      items: $environment.primary.items.map(i=>{
        const $itemEntry = $itemEntries.find(e => e[0] === i.language )
        return { language: $itemEntry[0], entry: { ...i.entry, ...$itemEntry[1]} }
      })
    },
    practices: $environment.practices.map(p=>({ target: p.target, final: environment.practices[p.target] || { include: false, proportion: 0 }  }))
  }

  data.environments.unshift($environment)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $environment._id,
    "model": "Environment",
    "action": "primary:update:environment",
    "data": $environment
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Environment '${$environment._id}' has been successfully updated`, action: "primary:update:environment", environment: $environment, }]
})

mock.onGet('/mocks/environments/logs').reply(config => {
  const { q = '', perPage = 10, page = 1, target = null } = config

  const queryLowered = q.toLowerCase()
  const filteredData = data.logs
    .filter(l=>l.target === target )
    .filter(l=>l?.action?.toLowerCase().includes(queryLowered))
    .sort(sortCompare('_id'))
    .reverse()

  return [
    200, {
      logs: paginateArray(filteredData, perPage, page),
      total: filteredData.length
    }
  ]
})

mock.onGet('/mocks/environments/usage').reply(config => {
  const { q = '', perPage = 10, page = 1, target = null } = config

  const users = data.users.filter(u=>u.environment === target)

  return [
    200, {
      usage: {
        users: users
      },
      total: 0
    }
  ]
})

mock.onDelete('/mocks/environments/delete').reply(async config => {
  const { _id } = config

  const environmentIndex = data.environments.findIndex(t => t._id === _id)
  const $environment = data.environments.splice(environmentIndex, 1)
  
  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $environment._id,
    "model": "Environment",
    "action": "primary:delete:environment",
    "data": $environment
  }

  data.logs.unshift($log)

  await delay(1000)

  return [200, { msg: `Environment '${_id}' has been successfully deleted`, action: "primary:delete:environment" }]
})


mock.onPost('/mocks/environments/remove/course').reply(async config => {
  const environment = JSON.parse(config.data)

  let $environment = data.environments.find(i => i._id === environment?.environmentId)
  if (!$environment) return [404, { msg: "Environment not found" }]

  data.environments = data.environments.filter(u=>u._id !== environment?.environmentId)

  $environment = { 
    ...$environment, 
    courses: $environment.courses.filter(l=>l !== environment.courseId)
  }

  data.environments.unshift($environment)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $environment._id,
    "model": "Environment",
    "action": "primary:update:environment:remove:course",
    "data": $environment
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Course '${environment.courseId}' has been successfully removed from environment ${environment?.environmentId}.`, action: "primary:update:environment:remove:course", environment: $environment, }]
})

mock.onPost('/mocks/environments/move/course').reply(async config => {
  const environment = JSON.parse(config.data)

  let $environment = data.environments.find(i => i._id === environment?.environmentId)
  if (!$environment) return [404, { msg: "Environment not found" }]

  data.environments = data.environments.filter(u=>u._id !== environment?.environmentId)

  $environment = { ...$environment, }


  const dir = environment.dir

  const _from = $environment.courses.findIndex(i=> i === environment.courseId)
  const d = dir === "up" ? -1 : dir === "down" ? 1 : 0
  const _to = _from + d

  move($environment.courses)(_from, _to);


  data.environments.unshift($environment)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $environment._id,
    "model": "Environment",
    "action": "primary:update:environment:move:course",
    "data": $environment
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Course '${environment.courseId}' index has been successfully moved in environment ${environment?.environmentId}.`, action: "primary:update:environment:move:course", environment: $environment, }]
})


mock.onPost('/mocks/environments/remove/practice').reply(async config => {
  const environment = JSON.parse(config.data)

  let $environment = data.environments.find(i => i._id === environment?.environmentId)
  if (!$environment) return [404, { msg: "Environment not found" }]

  data.environments = data.environments.filter(u=>u._id !== environment?.environmentId)

  $environment = { 
    ...$environment, 
    practices: $environment.practices.filter(l=>l.target !== environment.practiceId)
  }

  data.environments.unshift($environment)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $environment._id,
    "model": "Environment",
    "action": "primary:update:environment:remove:practice",
    "data": $environment
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Practice '${environment.practiceId}' has been successfully removed from environment ${environment?.environmentId}.`, action: "primary:update:environment:remove:practice", environment: $environment, }]
})

mock.onPost('/mocks/environments/move/practice').reply(async config => {
  const environment = JSON.parse(config.data)

  let $environment = data.environments.find(i => i._id === environment?.environmentId)
  if (!$environment) return [404, { msg: "Environment not found" }]

  data.environments = data.environments.filter(u=>u._id !== environment?.environmentId)


  const dir = environment.dir

  const _from = $environment.practices.findIndex(i => i.target === environment.practiceId)
  const d = dir === "up" ? -1 : dir === "down" ? 1 : 0
  const _to = _from + d

  move($environment.practices)(_from, _to);


  data.environments.unshift($environment)

  const $log = {
    "_id": "5b1fb75591a9cb165b0fa758",
    "target": $environment._id,
    "model": "Environment",
    "action": "primary:update:environment:move:practice",
    "data": $environment
  }

  data.logs.unshift($log)

  await delay(1000)

  return [201, { msg: `Practice '${environment.practiceId}' index has been successfully moved in environment ${environment?.environmentId}.`, action: "primary:update:environment:move:practice", environment: $environment, }]
})
