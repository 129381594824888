export const data = {
  roles: [
    {
        "_id": "5b3cc254e02cf03b11cc181b",
        "name": "Administrator",
        "code": "admin"
    },
    {
        "_id": "5b3cc265e02cf03b11cc181c",
        "name": "User",
        "code": "user"
    },
    {
        "_id": "5b3cc265e02cf03b11cc181d",
        "name": "Translator",
        "code": "translator",
        "privileges": [
          "custom:permissions"
        ]
    }
  ]
}

export const populate = ({ iter, }) => {
  let $item = JSON.parse(JSON.stringify({ ...iter }))
  return $item
}